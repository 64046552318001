<template>
  <div>
    <Header/>

    <loading
      :color="'blue'"
      :active.sync="isLoading"
      :can-cancel="false"></loading>

    <section id="login">
      <div
        class="bg"
        :style="`background-image: url(${require('@/assets/img/account/img_1.jpg')});`">
      </div>
      <div class="bg2"></div>
      <div class="inner w1300">
        <!-- <div class="breadcrumbs">
            <span class="txt-lightgrey">首頁</span> > 註冊
        </div> -->

        <div class="login_box">
          <div class="title_box">
            <h4 class="txt-bold">忘記密碼？</h4>
            <router-link
              :to="{ name: 'Login' }">
                <p>
                  返回登入 <img src="@/assets/img/icon/icon_next.svg" alt="">
                </p>
            </router-link>
          </div>

          <form>
            <div class="login_item">
              <p class="txt-greenlake">Email</p>
              <input type="email" v-model="email" placeholder="請輸入Email">
            </div>
            <br>
            <p>
              請輸入您加入會員所填寫的電子信箱， 系統將寄送新的密碼給您。<br>
              您可使用新密碼登入後再至會員中心變更密碼。
            </p>
            <br>
            <div class="btnbox txt-right">
              <button
                @click="submit"
                type="button"
                class="btn btn-blue_blue_line">
                  <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
                  <p>送出</p>
              </button>
            </div>
          </form>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import requestApi from '@/lib/http/index';
import { required, checkMail } from '@/consts/validator';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import '@/assets/scss/login.scss';

export default {
  name: 'ForgetPassword',
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  methods: {
    async submit() {
      const formData = {
        account: this.email,
      };
      const checkFormData = await this.checkFormData(formData);
      if (checkFormData) {
        this.isLoading = true;
        const { status, message } = await requestApi('member.forgetPassowrd', formData);
        if (status && message) {
          this.success(message);
        }
        if (!status && message) {
          this.error(message);
        }
        this.isLoading = false;
      }
    },
    success(v) {
      this.$customSWAL({
        icon: 'success',
        title: 'Success',
        text: v,
        confirmButtonText: '確定',
      });
    },
    error(v) {
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: v,
        confirmButtonText: '確定',
      });
    },
    checkFormData(formData) {
      let result = true;
      Object.keys(formData).every((key) => {
        console.log(key, formData[key]);
        if (!required(formData[key])) {
          this.error('請填寫Email');
          result = false;
          return false;
          // eslint-disable-next-line
        } else {
          const validateResult = checkMail(formData[key]);
          console.log(validateResult);
          if (!validateResult) {
            this.error('請輸入正確的Email');
            result = false;
            return false;
          }
          return true;
        }
      });
      if (result) {
        return true;
      }
      return false;
    },
  },
};
</script>
